<template>
	<div>
		<v-row class="mt-1 ml-2 mb-3">
			<v-col>
				<h2 class="primary--text">Tier 1</h2>
			</v-col>

			<v-col cols="12" md="2" sm="2" class="text-right">
				<!-- export payroll -->
				<v-menu
					transition="scale-transition"
					origin="center center"
					offset-y
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="primary"
							v-bind="attrs"
							v-on="on"
							@click="exportTierOne"
						>
							Export Payroll
							<v-icon class="pl-1" size="24"> mdi-export </v-icon>
						</v-btn>
					</template>
				</v-menu>
			</v-col>
		</v-row>

		<!-- Spinner -->
		<div v-if="isloading">
			<spinner></spinner>
		</div>
		<!-- search -->
		<v-row>
			<v-col cols="12" sm="3">
				<v-text-field
					@keyup.enter="handleFilter"
					solo
					prepend-inner-icon="mdi-magnify"
					label="Name"
					dense
					clearable
					flat
					@click:clear="resetData"
					color="primary"
					background-color="white lighten-4"
					v-model="filterForm.name"
				></v-text-field>
			</v-col>
			<v-col cols="12" sm="3" md="3">
				<v-select
					solo
					dense
					flat
					label="Department"
					:items="departments"
					item-text="name"
					item-value="abbreviation"
					v-model="filterForm.department"
				></v-select>
			</v-col>

			<!-- filter payroll -->
			<v-col cols="12" sm="3" md="3">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="filterForm.date"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							dense
							solo
							flat
							background-color="white"
							placeholder="yyyy-mm"
							v-model="filterForm.date"
							prepend-inner-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="filterForm.date"
						type="month"
						no-title
						scrollable
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="$refs.menu.save(filterForm.date)"
						>
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="12" md="3" sm="3" class="d-flex justify-content-between">
				<v-btn color="primary" @click="handleFilter">filter</v-btn>
				<v-btn color="#eff" class="primary--text" @click.prevent="resetData"
					>Clear</v-btn
				>
			</v-col>
		</v-row>
		<v-data-table
			fixed-header
			height="80vh"
			:headers="headers"
			:items="tierOneReport"
			disable-sort
			class="table-rounded elevation-1"
			:page.sync="page"
			@page-count="pageCount = $event"
			:items-per-page="itemsPerPage"
			hide-default-footer
		>
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template v-slot:[`item.fullName`]="{ item }">
				{{ item?.employee?.displayName }}
			</template>
			<template v-slot:[`item.customSSN`]="{ item }">
				{{ item?.employee?.employeeIdentification?.customSSN }}
			</template>
			<template v-slot:[`item.basicSalary`]="{ item }">
				{{ formatFigure(item?.employee?.basicSalary) }}
			</template>

			<template v-slot:[`item.tierOne`]="{ item }">
				{{ formatFigure(item.tierOne) }}
			</template>

			<template slot="body.append">
				<tr class="primary--text footer">
					<th id="" class="subtitle-2">TOTALS</th>
					<th id="" class="subtitle-2"></th>
					<th id="" class="subtitle-2"></th>
					<th id="" class="subtitle-2">
						{{ formatFigure(masterTierOneTotals.sumBasicSalary) }}
					</th>
					<th id="" class="subtitle-2">
						{{ formatFigure(masterTierOneTotals.sumPercentage) }}
					</th>
				</tr>
			</template>
			<template v-slot:no-data> NO DATA AVAILABLE ! </template>
		</v-data-table>
		<div class="text-center pt-2">
			<v-pagination
				v-model="page"
				:length="totalPages"
				@input="paginatePage()"
				circle
			></v-pagination>
		</div>
	</div>
</template>

<script>
import { EXPORT_TIER_ONE_REPORT } from "../../services/graphql/queries/payrollTierQueries";
import moment from "moment";
import helpers from "../../services/helpers";
import { GET_TIER_ONE_SUMMATION_REPORT } from "../../services/graphql/queries/columnSummations";
export default {
	mixins: [helpers],
	data: () => ({
		menu: false,
		itemsPerPage: 20,
		dialog: false,
		dialogDelete: false,
		isloading: false,
		departments: [
			{ name: "All Departments", abbreviation: "" },
			{ name: "Service Center", abbreviation: "SC" },
			{ name: "Training Center", abbreviation: "TRN" },
      { name: "Operations", abbreviation: "OP" },
      { name: "Management", abbreviation: "MG" },
		],
		filterForm: new Form({
			name: "",
			department: "",
			date: "",
		}),
		headers: [
			{
				text: "NO.",
				align: "start",
				sortable: false,
				value: "no",
			},
			{ text: "EMPLOYEE NAME", value: "fullName" },
			{ text: "SSNIT NUMBER", value: "customSSN" },
			{ text: "BASIC SALARY", value: "basicSalary" },
			{ text: "SSF (13.5%)", value: "tierOne" },
		],
		header: [
			"Name of Employee",
			"SSNIT Number",
			"Basic Salary",
			"SSF(13.5%)",
		],
		masterTierOneTotals: {},
	}),
	computed: {
		tierOneReport() {
			return this.$store.getters["report/allTierOneReport"];
		},
		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},
		totalPages() {
			return this.$store.getters["report/totalPages"];
		},
		page: {
			get() {
				return this.$store.getters["report/currentPage"];
			},
			set(val) {
				return this.$store.commit("report/SET_CURRENT_PAGE", val);
			},
		},
		currentDate() {
			let today = new Date();
			let date =
				today.getFullYear() +
				"-" +
				("0" + (today.getMonth() + 1)).slice(-2);
			return {
				yearMonth: date,
			};
		},
	},
	created() {
		this.filterForm.date = this.currentDate.yearMonth;
		this.initialize();
	},
	methods: {
		initialize() {
			let val = { yearMonth: this.filterForm.date };
			this.isloading = true;
			this.$store
				.dispatch("report/fetchTierOneReport", {
					page: this.page || 1,
					data: val,
				})
				.then(() => {
					this.isloading = false;
				})
				.catch(() => {
					this.isloading = false;
				});
			this.getMasterTierOneTotals();
		},

		resetData() {
			this.filterForm.reset();
			this.filterForm.date = this.currentDate.yearMonth;
			this.initialize();
		},

		getMasterTierOneTotals() {
			let today = new Date();
			let date =
				today.getFullYear() +
				"-" +
				("0" + (today.getMonth() + 1)).slice(-2);
			const data = {
				yearMonth: date,
			};
			this.$apollo
				.mutate({
					mutation: GET_TIER_ONE_SUMMATION_REPORT,
					variables: data,
				})
				.then((response) => {
					this.masterTierOneTotals =
						response.data.getTierOneSummationReport;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCurrentMonthYearFromDate(date) {
			const year = new Date(date).getFullYear();
			const month = new Date(date).getMonth() + 1;
			return `${year} ${month}`;
		},

		async exportTierOne() {
			this.isloading = true;
			const data = {
				name: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: `${this.filterForm.date}`,
			};

			const date = moment(data.yearMonth).format("MMM YYYY");
			this.$apollo
				.query({
					query: EXPORT_TIER_ONE_REPORT,
					variables: {
						employeeName: data.name,
						yearMonth: data.yearMonth,
						startWith: data.startWith,
						responseType: "blob",
						headers: { Accept: "multipart/form-data" },
					},
				})
				.then((response) => {
					const title = `SSNIT REPORT FOR - ${date}.xls`;
					const fileLink = response.data.exportTierOneReport;
					const a = Object.assign(document.createElement("a"), {
						href: fileLink,
						download: title,
					});
					a.click();
					a.remove();
					this.isloading = false;
				})
				.catch(() => {
					this.isloading = false;
				});
		},

		async handleFilter() {
			this.isloading = true;
			await this.$store.dispatch("report/filterTierOneReport", {
				page: 1,
				searchName: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: this.filterForm.date,
			});
			this.isloading = false;
		},
		async paginatePage() {
			this.isloading = true;
			await this.$store.dispatch("report/fetchTierOneReport", {
        page: this.page,
				data: { yearMonth: this.filterForm.date },
			});
			this.isloading = false;
      this.scrollToTop();
		},
		scrollToTop() {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
	},
};
</script>

<style>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}
</style>
